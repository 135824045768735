@import 'fonts';
@import 'utils';

$font-serif:Ovo,Charter,Georgia,Verdana,Tahoma,monospace;
$font-sans:Montserrat,Verdana,Tahoma,'Times New Roman';

$bg-vitruve: url(assets/vitruve.jpg);

*
{
	font-style:normal;
	font-weight:lighter;
	font-family:$font-serif;
	margin: 0;
	padding: 0;
}

html
{
	width: 100%;
	height: 100%;
	min-height: 100%;
}

body
{
	top: 0;
	height: 100%;
	min-height: 100%;
	overflow:visible;
}



$color-blue:	#001941;
$color-cyan:	#444444;
$color-gray:	#777777;

#root
{
	height: 100%;
}

.App
{
	position: relative;
	width: 100%;
	min-height: 100%;
	text-align: center;
}



.Background
{
	position: absolute;
	overflow: hidden;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 1;
	z-index: -1;
	background: #FFFFFF; /* Old browsers */
}

.Background.Center
{
    height: 0;
    padding-top: 80%;
	background-size: 100vmax;
	background-position-y: 80%;
	background-repeat: no-repeat;
	background-image:	-webkit-linear-gradient(top,		rgba(#FFF, 0.2) 0%, #FFFFFF 60%), $bg-vitruve; /* Chrome10-25,Safari5.1-6 */
	background-image:	   -moz-linear-gradient(top,		rgba(#FFF, 0.2) 0%, #FFFFFF 60%), $bg-vitruve; /* FF3.6-15 */
	background-image:		 -o-linear-gradient(top,		rgba(#FFF, 0.2) 0%, #FFFFFF 60%), $bg-vitruve; /* Opera */
	background-image:			linear-gradient(to bottom,	rgba(#FFF, 0.2) 0%, #FFFFFF 60%), $bg-vitruve; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#00FFFFFF', GradientType=0); /* IE6-9 */
}

.Background.Left
{
    height: 0;
    padding-top: 80%;
	background-size: 100vmax;
	background-repeat: no-repeat;
	background-image:	-webkit-linear-gradient(left,		rgba(#FFF, 0) 0%, #FFFFFF 60%), $bg-vitruve; /* Chrome10-25,Safari5.1-6 */
	background-image:	   -moz-linear-gradient(left,		rgba(#FFF, 0) 0%, #FFFFFF 60%), $bg-vitruve; /* FF3.6-15 */
	background-image:		 -o-linear-gradient(left,		rgba(#FFF, 0) 0%, #FFFFFF 60%), $bg-vitruve; /* Opera */
	background-image:			linear-gradient(to right,	rgba(#FFF, 0) 0%, #FFFFFF 60%), $bg-vitruve; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#00FFFFFF', GradientType=0); /* IE6-9 */
}

.Background.Right
{
    height: 0;
    padding-top: 80%;
	background-size: 100vmax;
	background-repeat: no-repeat;
	background-image:	-webkit-linear-gradient(215deg,	rgba(#FFF, 0) 0%, #FFFFFF 60%), $bg-vitruve; /* Chrome10-25,Safari5.1-6 */
	background-image:	   -moz-linear-gradient(215deg,	rgba(#FFF, 0) 0%, #FFFFFF 60%), $bg-vitruve; /* FF3.6-15 */
	background-image:		 -o-linear-gradient(215deg,	rgba(#FFF, 0) 0%, #FFFFFF 60%), $bg-vitruve; /* Opera */
	background-image:			linear-gradient(215deg,	rgba(#FFF, 0) 0%, #FFFFFF 60%), $bg-vitruve; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#00FFFFFF', GradientType=0); /* IE6-9 */
}

.Background > img
{
	position: relative;
	display: block;
	top: -10%;
	left: 0;
	max-width: 100%;
	max-height: 40%;
	opacity: 0.5;
}



h1
{
	color: $color-cyan;
	font-size:1.5vmax;
}

h2
{
	color: $color-blue;
	font-size:2vmax;
	text-align: center;
	margin-bottom: 20px;
}

h3
{
	width: 100%;
	color: $color-gray;
	font-size:3vmax;
	text-align: center;
	margin-top: 60px;
	margin-bottom: 20px;
}

h4
{
	position:absolute;
	margin-top: 15%;
	margin-left: 10%;
	width: 80%;
	color: $color-gray;
	font-size:2vmax;
	text-align: center;
}

h5
{
	margin-top: 10px;
	color: #FFFFFF;
}



p, ul, ol
{
	display:block;
//	text-indent: 40px;
	color: $color-blue;
	font-size:1.5vmax;
}

p > a
{
	font-family:$font-serif;
	font-size:1.5vmax;
	color: $color-cyan;
	text-decoration: none;
	border-bottom: 1px solid $color-gray;
}

a
{
	font-family:$font-sans;
	font-weight: 500;
	font-size:1.4vmax;
	color:#FFFFFF;
	text-decoration: none;
}

a:hover
{
	color: $color-gray;
}

li
{
	margin-left: 2vw;
	margin-top: 10px;
	margin-bottom: 10px;
	text-indent: 0;
}



$header-height: 5vmax;

header
{
	position: fixed;
	top: -$header-height;
	height: $header-height;
	left: 0;
	width: 100%;
	color: #FFFFFF;
	background: $color-blue;
	z-index: 1;
	@include transition(top .5s, height .2s);
}

.HeaderBG
{
	position: absolute;
	top: 0;
	width: 100%;
	height: $header-height;
	background: $color-blue;
}

$applogo-left:50vmax;
$applogo-top: 2.5vmax;
$applogo-w:10vmax;
$applogo-h: 5vmax;
$applogo-hover-w:4vmax;
$applogo-hover-h:2vmax;
.AppLogo
{
	display: block;
	position: absolute;
	left:	$applogo-left - ($applogo-w) / 2;
	top:	$applogo-top  - ($applogo-h) / 2;
	width:	$applogo-w;
	height:	$applogo-h;
//	@include transition(left .1s, top .1s, width .1s, height .1s);
}
.AppLogo > img
{
	display:block;
	left: 0;
	top: 0;
	width: 100%;
	height:100%;
}
.AppLogo:click
{
	left:	$applogo-left - ($applogo-w + $applogo-hover-w) / 2;
	top:	$applogo-top  - ($applogo-h + $applogo-hover-h) / 2;
	width:	$applogo-w + $applogo-hover-w;
	height:	$applogo-h + $applogo-hover-h;
	cursor: pointer;
}

.NavBar
{
	pointer-events: none;
	position: absolute;
	top: 2vmax;
	left: 2vw;
	width: 96vw;
	display: flex;
	flex-wrap: wrap;
}

.NavBarLink
{
	pointer-events: auto;
	flex-grow: 1;
	width: 14%;
}



$footer-height: 25vmax;

footer
{
	position: absolute;
	margin-top: 5vh;
	left: 0;
	bottom: 0;
	width: 100%;
	height: $footer-height;
	background-color: #00112C;
	> img
	{
		display: block;
		position: absolute;
		z-index: 0;
		left:	38px;
		top:	0px;
		width:	180px;
		height:	 90px;
	}
	> p
	{
		text-align: left;
		margin-left: 50px;
		margin-top: 80px;
		font-size: 1.2vmax;
		font-family: Charter,Georgia,Verdana,Tahoma,monospace;
		a
		{
			font-size: 1.2vmax;
			font-family: Charter,Georgia,Verdana,Tahoma,monospace;
		}
	}
}

.Footer.NavBar
{
	position: absolute;
	top: 5vh;
	left: 60%;
	width: 40%;
	height: 50%;
	text-align: left;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
}

.FooterLink
{
	pointer-events: auto;
	width: 50%;
	height: 33%;
}

.FooterLink > a
{
	font-size: 1.2vmax;
}

.FooterLink.Legal
{
	position: absolute;
	text-align: center;
	bottom: 10px;
	left: 0;
	width: 100%;
	height: auto;
	> span
	{
		font-size: 1vmax;
		font-family: $font-serif;
	}
	> a
	{
		font-size: 1vmax;
		font-family: $font-serif;
		text-decoration: none;
		color: $color-gray;
	}
}

.FooterText
{
	position: absolute;
}



.PageTitle
{
	position: absolute;
	color: $color-gray;
	font-size: 8vmax;
}
.PageTitle.Left
{
	left:8vw;
	top:10vmax;
}
.PageTitle.Right
{
	right:8vw;
	top:10vmax;
}
.PageTitle.Center
{
	width: 100%;
	left: 0;
	top:10vmax;
}



.TextBox
{
	overflow: hidden;
	text-align: justify;

}
.TextBox.Left
{
	width: 40%;
	padding-left: 10%;
	padding-top: 10vmax;
	padding-bottom: 30vmax;
}
.TextBox.Right
{
	width: 40%;
	padding-left: 50%;
	padding-top: 10vmax;
	padding-bottom: 30vmax;
}
.TextBox.Center
{
	width: 60vw;
	padding-left: 20vw;
	padding-top: 25vmax;
	padding-bottom: 30vmax;
}



$card-margins: 1vmax;

.PageTitle.Center.CardsTitle
{
	top: 10vmax;
}

.Deck
{
	padding: 20vmax 10% 30vmax 10%;
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	box-sizing: border-box;
}

.Card
{
	position: relative;
	display: flex;
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: 0%;
	flex-direction: column;
	min-width: 280px;
	text-align: left;
	color: $color-gray;
	background-clip: border-box;
	border: 1px solid $color-gray;
	margin:  $card-margins $card-margins $card-margins $card-margins;
	padding: $card-margins $card-margins $card-margins $card-margins;
	padding-bottom: 12vmax;
}

.Card::before	{ box-sizing: border-box; }
.Card::after	{ box-sizing: border-box; }

.Card > .Title
{
	display: table;
	height: 12vmax;
	h2
	{
		display: table-cell;
		vertical-align: middle;
	}
}

.Tags
{
	display: inline-block;
	align-content: flex-end;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}
.Tags > span
{
	display: inline-block;
	margin: 20px;
	padding: 10px;
	color: #FFFFFF;
	bottom: 0;
}



@media screen and (max-width:800px)
{
	.HeaderBG
	{
		height: 80px;
	}

	header
	{
		top:	-80px;
		height:	 80px;

		.AppLogo
		{
			display: block;
			position: absolute;
			left:	20px;
			top:	 5px;
			width:	140px;
			height:	 70px;
//			@include transition(left .1s, top .1s, width .1s, height .1s);
		}
		.AppLogo:click
		{
			left:	20px - (140px + 40px) / 2;
			top:	 5px - ( 70px + 20px) / 2;
			width:	160px + 40px;
			height:	 80px + 20px;
			cursor: pointer;
		}
		button
		{
			display: block;
			pointer-events: auto;
			position: absolute;
			top:   12px;
			right: 20px;
			width:	60px;
			height:	60px;
			background-color: transparent;
			border: none;
		}
		img
		{
			width:	40px;
			height:	40px;
		}
		a
		{
			margin-top: 20px;
			width: 100%;
		}
	}

	footer
	{
		height: 40vmax;
		> img
		{
			display: block;
			left: 20px;
			top: 0;
			width:	160px;
			height:	 80px;
		}
		> p
		{
			margin-top: 70px;
			margin-left: 30px;
			font-size: 2vmax; 
			a
			{
				font-size: 2vmax;
			}
		}
	}

	h1		{	font-size: 3vmin;	}
	h2		{	font-size: 5vmin;	}
	h3		{	font-size: 6vmin;	}
	p		{	font-size: 4vmin;	}
	ol, ul	{	font-size: 4vmin;	}
	p > a	{	font-size: 4vmin;	}
	.Card > ol,
	.Card > ul
	{
		font-size: 3vmin;
	}

	a
	{
		font-size: 10pt;
		line-height: 2.4vmax;
	}
	.FooterLink > a
	{
		font-size: 10pt;
		line-height: 2.4vmax;
	}

	.NavBar.Header
	{
		top: 20vh;
		left: 10vw;
		width: 80vw;
		text-align: center;
		cursor: default;
		> div { width: 100%; }
		a
		{
			font-size: 5vmin;
			line-height: 10vmin;
			pointer-events: none;
			opacity: 0;
		}
	}
	.NavBar.Header.Display
	{
		a
		{
			pointer-events: auto;
			opacity: 1;
		}
		> #link0 a { @include transition(opacity 0.4s); }
		> #link1 a { @include transition(opacity 0.8s); }
		> #link2 a { @include transition(opacity 1.2s); }
		> #link3 a { @include transition(opacity 1.6s); }
		> #link4 a { @include transition(opacity 2.0s); }
		> #link5 a { @include transition(opacity 2.4s); }
	}
	.NavBarLink, 
	{
		pointer-events: auto;
		width: 100%;
	}
	.NavBar.Footer
	{
		left: 70%;
		width: 30%;
		height: 70%;
		text-align: left;
	}
	.FooterLink
	{
		margin-right: 30px;
		width: 100%;
		height: auto;
	}
	.FooterLink.Legal
	{
		margin: 0;
		width: 100%;
		> a		{ font-size: 3vmin; }
		> span	{ font-size: 3vmin; }
	}



	.TextBox.Left,
	.TextBox.Right,
	.TextBox.Center
	{
		text-align: justify;
		width: 80vw;
		padding-left: 10vw;
		padding-top: 50vmax;
		padding-bottom: 45vmax;
	}

	.PageTitle.Left,
	.PageTitle.Right,
	.PageTitle.Center
	{
		font-size: 12vmin;
		width: 100%;
		left: 0;
		top:30vmax;
	}

	.PageTitle.Center.CardsTitle
	{
		top: 18vmax;
	}
	.Card
	{
		min-width: 80vw;
	}
	.Deck
	{
		padding-top: 30vmax;
		padding-bottom: 45vmax;
	}

	.Background.Center
	{
	    height: 0;
	    padding-top: 80%;
		background-size: 100vw;
		background-position-y: -40%;
		background-repeat: no-repeat;
		background-image:	-webkit-linear-gradient(top,		rgba(#FFF, 0.2) 0%, #FFFFFF 100%), $bg-vitruve; /* Chrome10-25,Safari5.1-6 */
		background-image:	   -moz-linear-gradient(top,		rgba(#FFF, 0.2) 0%, #FFFFFF 100%), $bg-vitruve; /* FF3.6-15 */
		background-image:		 -o-linear-gradient(top,		rgba(#FFF, 0.2) 0%, #FFFFFF 100%), $bg-vitruve; /* Opera */
		background-image:			linear-gradient(to bottom,	rgba(#FFF, 0.2) 0%, #FFFFFF 100%), $bg-vitruve; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#00FFFFFF', GradientType=0); /* IE6-9 */
	}

	.Background.Left
	{
		margin-top: 80px;
	    padding-top: 100%;
		background-size: 100vw;
		background-repeat: no-repeat;
		background-image:	-webkit-linear-gradient(top left,		rgba(#FFF, 0) 0%, #FFFFFF 60%), $bg-vitruve; /* Chrome10-25,Safari5.1-6 */
		background-image:	   -moz-linear-gradient(top left,		rgba(#FFF, 0) 0%, #FFFFFF 60%), $bg-vitruve; /* FF3.6-15 */
		background-image:		 -o-linear-gradient(top left,		rgba(#FFF, 0) 0%, #FFFFFF 60%), $bg-vitruve; /* Opera */
		background-image:			linear-gradient(to bottom right,rgba(#FFF, 0) 0%, #FFFFFF 60%), $bg-vitruve; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#00FFFFFF', GradientType=0); /* IE6-9 */
	}

	.Background.Right
	{
		margin-top: 80px;
	    padding-top: 100%;
		background-size: 100vw;
		background-repeat: no-repeat;
		background-image:	-webkit-linear-gradient(top right,		rgba(#FFF, 0) 0%, #FFFFFF 60%), $bg-vitruve; /* Chrome10-25,Safari5.1-6 */
		background-image:	   -moz-linear-gradient(top right,		rgba(#FFF, 0) 0%, #FFFFFF 60%), $bg-vitruve; /* FF3.6-15 */
		background-image:		 -o-linear-gradient(top right,		rgba(#FFF, 0) 0%, #FFFFFF 60%), $bg-vitruve; /* Opera */
		background-image:			linear-gradient(to bottom left,	rgba(#FFF, 0) 0%, #FFFFFF 60%), $bg-vitruve; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#00FFFFFF', GradientType=0); /* IE6-9 */
	}
}
