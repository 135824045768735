
@mixin transition($args...)
{
	-webkit-transition: $args;
	   -moz-transition: $args;
		-ms-transition: $args;
		 -o-transition: $args;
			transition: $args;
}

@mixin transition-property($args...)
{
	-webkit-transition-property: $args;
	   -moz-transition-property: $args;
		-ms-transition-property: $args;
		 -o-transition-property: $args;
			transition-property: $args;
}

@mixin transition-duration($args...)
{
	-webkit-transition-duration: $args;
	   -moz-transition-duration: $args;
		-ms-transition-duration: $args;
		 -o-transition-duration: $args;
			transition-duration: $args;
}



@mixin transform($args)
{
	-webkit-transform: $args;
	   -moz-transform: $args;
		-ms-transform: $args;
		 -o-transform: $args;
			transform: $args;
}

@mixin transform-style($args)
{
	-webkit-transform-style: $args;
	   -moz-transform-style: $args;
		-ms-transform-style: $args;
		 -o-transform-style: $args;
			transform-style: $args;
}



@mixin perspective($args)
{
	-webkit-perspective: $args;
	   -moz-perspective: $args;
		-ms-perspective: $args;
		 -o-perspective: $args;
			perspective: $args;
}



@mixin backface-visibility($args)
{
	-webkit-backface-visibility: $args;
	   -moz-backface-visibility: $args;
		-ms-backface-visibility: $args;
		 -o-backface-visibility: $args;
			backface-visibility: $args;
}



@mixin user-select($args)
{
	-webkit-user-select: $args;
	   -moz-user-select: $args;
		-ms-user-select: $args;
		 -o-user-select: $args;
			user-select: $args;
}
