
@font-face
{
	font-family: 'Georgia';
	src: local(Georgia);
	src: url(./fonts/Georgia.eot);
	src: url(./fonts/Georgia.eot?#iefix) format('embedded-opentype'),
		 url(./fonts/Georgia.otf) format('opentype'),
		 url(./fonts/Georgia.ttf) format('truetype'),
		 url(./fonts/Georgia.woff) format('woff'),
		 url(./fonts/Georgia.woff2) format('woff2'),
		 url(./fonts/Georgia.svg#JosefinSans-Light) format('svg');
}

@font-face
{
	font-family: 'Charter';
	src: local(Charter);
	src: url(./fonts/Charter.eot);
	src: url(./fonts/Charter.eot?#iefix) format('embedded-opentype'),
		 url(./fonts/Charter.otf) format('opentype'),
		 url(./fonts/Charter.ttf) format('truetype'),
		 url(./fonts/Charter.woff) format('woff'),
		 url(./fonts/Charter.woff2) format('woff2'),
		 url(./fonts/Charter.svg#JosefinSans-Light) format('svg');
}

@font-face
{
	font-family: 'Montserrat';
	src: local(Montserrat);
	src: url(./fonts/Montserrat.eot);
	src: url(./fonts/Montserrat.eot?#iefix) format('embedded-opentype'),
		 url(./fonts/Montserrat.otf) format('opentype'),
		 url(./fonts/Montserrat.ttf) format('truetype'),
		 url(./fonts/Montserrat.woff) format('woff'),
		 url(./fonts/Montserrat.woff2) format('woff2'),
		 url(./fonts/Montserrat.svg#JosefinSans-Light) format('svg');
}
